import React, {useState} from "react";
// import Stars from "./stars";
const ChamberReviews = () =>{

    const [isMore, setMore] = useState(false)
    const handleClick = () => {
        return setMore(!isMore)
    }
        
    const reviewsChamb = [
        {
            name:"S. P.",
            date:"2 Aug",
            text:"We had an excellent experience with Rene Godines Construction to add a gate and repair a portion of our fence. Rene and his crew were incredibly knowledgeable, responsive, and timely in completing the work. The work is top quality and we are thrilled with the results! We are looking forward to using him for future projects around the house. Rene Godines - (404) 884-9284",
            city:"Chamblee, GA"
        },       
        {
            name:"B. M.",
            date:"27 Dec",
            text:"Rene ended up working on my home that I was selling to repair and paint the entire interior of the home. He did a fantastic job, went above and beyond what he said he was going to do, and completed the job in the time he stated to fit our timeline. I will definitely leverage him again for our next project. Thanks Rene!",
            city:"Tucker, GA"
        },
        {
            name:"D. B.",
            date:"8 Oct",
            text:"Rene Godines recently did a few carpentry projects for me. He widened my refrigerator cabinetry to accommodate a larger unit and repurpose the cabinet above and finished with trim wood. Also did wood repair on French doors.  Very detail-oriented and knows what's he's doing. Going to use on a few more projects to include building a bridge over rock creek bed. Highly recommend.  Rene Godines (404) 884-9284",
            city:"Atlanta, GA "
        },
        {
            name:"C. M.",
            date:"22 Jul 22",
            text:"Rene Godines Construction & Remodeling. Last month Rene tiled my outside deck. It looked great but when it rained it became apparent that there was a huge section that was not draining thereby leaving a huge area of water . When I contacted his partner to let him know that there might be a problem I heard nothing back. I contacted Rene as he was also supposed to be doing a bath remodel for me. No response. I was gone for 10 days and upon my return tried again to contact Rene. Again no response. After the subsequent rains this week I am also having luggage onto my downstairs screen porch. This has been a very frustrating experience.",
            city:"Atlanta, GA"
        },
        {
            name:"H. S.",
            date:"7 Jul 22",
            text:"Rene Godines Construction & Remodeling. Rene just finished remodeling our front porch. Looks great and at a very reasonable price.",
            city:"Atlanta, GA"
        },
        {
            name:"B. P.",
            date:"6 Jan 22",
            text:"Rene Godines Construction. Rene was excellent. Built my wooden fence in 1 day with 2 days notice. Craftsmanship was excellent as well. ",
            city:"Atlanta, GA"
        },
        {
            name:"D. W.",
            date:"25 Sep 21",
            text:"Rene Godines Construction. great work, careful, on time! ",
            city:"Atlanta, GA"
        },
        {
            name:"M. H.",
            date:"18 Sep 21",
            text:"Handyman or handy-person to recommend? Thanks for your input! I would love your input as to someone capable, reliable and reasonably priced. Thanks in advance!",
            city:"Atlanta, GA"
        },
        {
            name:"E. H.",
            date:"4 Apr 21",
            text:"Rene Godines Construction. Rene came in and totally transformed our hallway bathroom . His work was great, attention to detail impeccable and efficient with time. Within two weeks we are using the bathroom again and the damage to the dining room ceiling, as if it never happened. His crew were always nice, very helpful and willing to help with any questions. I have never had anyone who cleaned up behind themselves so thoroughly. I barely had to do more than wipe down the surfaces. He was quick to start and was always asking questions about the progress to ensure that we were happy. All my words are positive as far as this entire renovation is concerned. He even took it upon himself to touch up a few dings in the hallway without even being asked. So considerate and thrilled to see that you are totally satisfied. Highly recommended.",
            city:"Atlanta, GA"
        },
        {
            name:"S. G.",
            date:"19 Jan 21",
            text:"Rene Godines Construction. Rene renovated our laundry room (drywall work, new trim, new tile, electrical and paint). He did a great job at an affordable price. Will definitely use him again.",
            city:"Chamblee, GA"
        },
        {
            name:"A. L.",
            date:"11 Jan 21",
            text:"Rene Godines Construction. Rene did a nice job in my laundry room installing a countertop, cabinets, drywall repair and moving my washer connections. His price was reasonable and he worked hard to complete the job in one day.",
            city:"Keller, TX"
        },
        {
            name:"R. A.",
            date:"25 Dec 20",
            text:"Rene Godines Construction. Rene just did some repairs on my basement dry wall and also did some trim work.  He is a pleasure to work with and did a great job!  Will definately consider Rene for other home projects.",
            city:"Atlanta, GA"
        },
        
    ]

    return(
        <section className="w-full py-20 flex flex-col gap-10">
            <h2 className="text-center">Our Reviews</h2>        
            <a href="https://static.nextdoor.com/pages/rene-godines-construction-atlanta-ga/"
                target="_blank"
                rel="noreferrer"
                className="flex w-[300px] mx-auto justify-center">
                    <img
                    alt="Logo-directory"
                    src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnextdoor.png?alt=media&token=52453f70-a4c9-4598-8356-eb0cb83b1d7d"
                   />
                </a>          
                {
                    isMore?
                    reviewsChamb.map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-full lg:w-4/5 min-h-[100px] mx-auto rounded-lg shadow-lg">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3 flex flex-col">
                                    <span className="font-medium">{item.name}</span>
                                    <span>{item.city}</span>
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>

                        </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto rounded-lg shadow-lg">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3 flex flex-col">
                                    <span className="font-medium" >{item.name}</span>
                                    <span>{item.city}</span>
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button>
        </section> 
    );

}

export default ChamberReviews